














import { defineComponent, PropType } from "@vue/composition-api";
import { RasmusUserDetail } from "@/features/rasmus-kids-club/shared";
import { RasmusChangePasswordForm } from "@/features/rasmus-kids-club/users/reset_password";

import { RasmusShowUserSettings } from ".";

export default defineComponent({
  name: "RasmusShowUser",
  components: {
    RasmusUserDetail,
    RasmusChangePasswordForm,
  },
  props: {
    settings: {
      type: Object as PropType<RasmusShowUserSettings>,
      default: undefined,
    },
  },
  setup(props) {
    const settings: RasmusShowUserSettings = props.settings;
    const userData = settings.user;
    const editLink = settings.editUrl;

    return {
      editLink,
      userData,
    };
  },
});
