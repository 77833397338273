/* eslint-disable no-useless-escape */
import Vue from "@/helper/initVue";

import {
  RasmusShowUser,
  RasmusShowUserConfig,
} from "@/features/rasmus-kids-club/users/show";

export const initRasmusShowUser = () => {
  const el = document.getElementById("rasmus-show-user");

  if (!el) {
    return;
  }

  try {
    const dataSettings = el.getAttribute("data-settings");

    if (!dataSettings) {
      return;
    }

    const parsedSettings: RasmusShowUserConfig = JSON.parse(dataSettings);

    new Vue({
      el,
      render: (h) =>
        h(RasmusShowUser, {
          props: {
            settings: parsedSettings,
          },
        }),
    });
  } catch (err) {
    console.error('Failed to load module "RasmusShowUser"');
  }
};

initRasmusShowUser();
